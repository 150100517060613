import React, { useState, useEffect } from "react";
import "./RollerText.css";

import "../elements.css";

function RollerText({ name, profession }) {
	const [position, setPosition] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setPosition((prevPosition) =>
				prevPosition === 0
					? window.innerWidth <= 600
						? -124
						: -196
					: 0
			);
		}, 3500); // Set delay between changes here
		return () => clearInterval(interval);
	}, []);

	const textStyle = {
		transform: `translateY(${position}px)`,
		transition: "all 1s ease-in-out",
	};

	return (
		<div className="roller">
			<h1 style={textStyle}>
				{name}
				<br />
				{profession}
			</h1>
		</div>
	);
}

export default RollerText;
