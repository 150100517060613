import React from "react";

import "./elements.css";

import LinkedInIcon from "./assets/linkedin.svg";
import InstagramIcon from "./assets/instagram.svg";
import TwitterIcon from "./assets/twitter.svg";
import MediumIcon from "./assets/medium.svg";
import BlogIcon from "./assets/blog.svg";

import Correction from "./Correction";
import RollerText from "./components/RollerText";

export default function Hero() {
	const Roller = ({ name, title }) => (
		<div className="roller">
			<span className="rolltext">
				<h1>
					{name}
					<br />
					{title}
				</h1>
			</span>
		</div>
	);

	return (
		<section id="Hero">
			<div className="titleContainer">
				<div className="innerContainer">
					<div>
						<RollerText name="Asma" profession="Writer" />

						<RollerText name="Irfan" profession="Author" />
					</div>

					<p className="b16 scrollText">Scroll Down</p>
					{/* <h1>
						Asma <br /> Irfan.
					</h1> */}

					{/* <div className="roller">
						<span className="rolltext">
							<h1>
								Asma
								<br />
								Writer
							</h1>
						</span>
					</div>
					<div className="roller" style={{ marginTop: 16 }}>
						<span  className="rolltext">
							<h1>
								Irfan
								<br />
								Author
							</h1>
						</span>
					</div> */}
				</div>

				<div className="socials" style={{ marginLeft: "auto" }}>
					<a
						href="https://asmairfan2002.medium.com/"
						target={"_blank"}
					>
						<img src={MediumIcon} alt="" />
					</a>
					{/* <a
						href="https://www.instagram.com/asmairfan234/"
						target={"_blank"}
					>
						<img src={InstagramIcon} alt="" />
					</a> */}
					<a
						href="https://www.linkedin.com/in/asmairfan234"
						target={"_blank"}
					>
						<img src={LinkedInIcon} alt="" />
					</a>
					<a
						href="https://twitter.com/irfanasma234"
						target={"_blank"}
					>
						<img src={TwitterIcon} alt="" />
					</a>
					<a
						href="https://asmairfan.wixsite.com/writerssanctuary"
						target={"_blank"}
					>
						<img src={BlogIcon} alt="" />
					</a>
				</div>
			</div>
			<div className="subContainer">
				<h6>Content Writer • Psychologist • Researcher • Editor</h6>
			</div>
		</section>
	);
}
