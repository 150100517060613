import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Hero from "./Hero";
import WorkTemplate from "./WorkTemplate";

import LinkedInIcon from "./assets/linkedin.svg";
import InstagramIcon from "./assets/instagram.svg";
import TwitterIcon from "./assets/twitter.svg";
import MediumIcon from "./assets/medium.svg";
import BlogIcon from "./assets/blog.svg";

import "./elements.css";
import Correction from "./Correction";
import ImageSrc from "./assets/image.JPEG";

import emailjs from "@emailjs/browser";

function App() {
	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 768px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(min-width: 768px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_tb997xs",
				"template_elubjfw",
				form.current,
				"T2xGuxkfmTnU6x9yd"
			)
			.then(
				(result) => {
					console.log(result.text);
					alert("Your message is sent!");
				},
				(error) => {
					console.log(error.text);
					alert("Something went wrong!");
				}
			);
	};

	function SubscriptionForm() {
		function sendEmail(e) {
			e.preventDefault();

			emailjs
				.sendForm(
					"service_tb997xs",
					"template_hkucdyp",
					form.current,
					"T2xGuxkfmTnU6x9yd"
				)
				.then(
					(result) => {
						console.log(result.text);
					},
					(error) => {
						console.log(error.text);
					}
				);
		}

		return (
			<form
				onSubmit={sendEmail}
				className="subscribeFrom"
				style={{ marginTop: 32 }}
			>
				{/* <input
					type="email"
					name="emailSubscribe"
					placeholder="Email"
					style={{
						marginBottom: 16,
						backgroundColor: "#fff",
					}}
				/> */}
				<input
					type="text"
					name="user_email"
					id="user_email"
					style={{
						marginBottom: 16,
						backgroundColor: "#fff",
					}}
				/>
				<button type="submit" className="btnText24">
					Subscribe
				</button>
			</form>
		);
	}

	return (
		<div className="App">
			<script
				type="text/javascript"
				src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"
			></script>
			{/* <script type="text/javascript">
                {(function () {
                    emailjs.init("<YOUR_PUBLIC_KEY>");
                })()}
                ;
            </script> */}
			{/* <Nav /> */}
			<div className="Content">
				<Hero />

				<section id="hello" className="grid2">
					<div className="heading">
						<h2 className="outlinedB">
							Hello, <br />{" "}
							{matches ? (
								<Correction
									text="stranger."
									correct="or a soon to be friend?"
									color="white"
									style={{
										webkitTextStroke: "0",
										top: 94,
										right: -80,
										fontSize: 54,
									}}
								/>
							) : (
								<Correction
									text="stranger."
									correct="or a soon to be friend?"
									color="#183150"
									style={{
										webkitTextStroke: "0",
										top: 50,
										right: -80,
										fontSize: 40,
									}}
								/>
							)}
						</h2>
					</div>
					<div className="subContent">
						<p className="b28">
							<Correction
								correct={"cut this out"}
								text={"Hey,"}
								color={"white"}
							/>
							it’s nice to see you here! I welcome you to the
							humble abode of a passionate writer. I have been
							offering my services online as a content writer for
							four years now and needless to say, it has been one
							of the best decisions I’ve made in my life. In
							today's content-producing market, it's a jungle out
							there, with countless creators vying for attention.
							It's hard to know who to trust and believe when
							everyone claims to be the best. But fear not,
							because I've got something that speaks louder than
							mere words - my work!
						</p>
					</div>
				</section>

				<section id="services" className="grid2">
					<div className="heading">
						<h2>Write</h2>
						<h2 style={{ fontWeight: 400 }}>Wrote</h2>
						<h2 style={{ fontWeight: 275 }}>Written</h2>
						<p className="b20">
							"A word after a word after a word is power." <br />
							--Margaret Atwood
						</p>
					</div>
					<div className="subContent">
						<p className="b28">
							While I{" "}
							<Correction
								text={"sincerely "}
								correct={"wholeheartedly"}
								style={{ right: -40 }}
							/>
							believe in the wisdom of the proverb "show, don't
							tell," I also recognize the importance of assuring
							you, as a content writer that I will go the extra
							mile to meet your expectations. One thing I
							guarantee above all else is an unwavering commitment
							to deadlines and dedication to delivering unmatched
							writing, no matter the challenge. <br /> <br />
							Having been on the other side of the equation, I
							truly understand what clients seek in content:
							exceptional quality, captivating headlines, verified
							facts and figures, engaging readability, an
							appropriate tone, and, of course, originality. It is
							my solemn promise that you will find each and every
							one of these features in the final content I create
							for you. <br />
							<br />
							So, Let me show you why I stand out in this
							competitive landscape, and together, we'll make your
							vision come to life!
							{/* {matches ? (
								<Correction
									text="in"
									correct="of"
									style={{ right: 0 }}
								/>
							) : (
								" of "
							)} */}
							{/* the proverb; show don't tell, yet if there is
							anything I can guarantee you as a content writer
							offering my services in the market, it is that I
							will be consistent with my deadlines and will try my
							utmost to deliver an unmatchable piece of writing to
							you, no matter what it takes. Having been on the
							other side of the picture, I understand a client's
							primary demands: top-notch quality content, catchy
							headlines, authenticated facts and figures, engaging
							to read, appropriate tone, and last (but of course
							not least) originality. I will ensure that you find
							all the mentioned features in the final content I
							produce for you. So, rest assured{" "}
							{matches ? (
								<Correction
									text="that"
									correct="because"
									style={{ top: 24, right: -24 }}
								/>
							) : (
								" because "
							)}
							your projects are in safe hands! */}
						</p>
					</div>
				</section>

				<section id="work" className="grid2">
					<div className="heading">
						<h2 className="outlinedW">
							Stories, <br /> I've written.
						</h2>
					</div>
					<div className="subContent">
						<p className="b28">
							Over the years, I have gained{" "}
							{matches ? (
								<Correction
									text={"proficiency"}
									correct={"competence"}
									color="#183150"
									style={{ right: 0 }}
								/>
							) : (
								" competence "
							)}
							together with experience in article writing,
							ghostwriting, and creative writing in almost all
							niches including cryptocurrency, alternative health
							& wellness, education, lifestyle, and psychology.{" "}
							{/* As
							a principal, I try and have an open-minded, creative
							approach to my work so there is room for my personal
							learning as well as increased satisfaction of my
							clients. */}
						</p>
						{/* <a href="#" className="allWork row">
                            <p className="btnText20">View all</p>
                            <img src={ArrowRightIcon} alt="arrow right" />
                        </a> */}
					</div>

					<WorkTemplate
						dark={matches ? false : true}
						URL="https://www.psychreg.org/how-anxiety-taught-me-most-important-lesson-my-life/"
						bgImage={
							"https://www.psychreg.org/wp-content/uploads/2021/06/mental-health.jpg"
						}
						date={"Jul 13, 2023"}
						title={
							"How Anxiety Taught Me the Most Important Lesson of My Life"
						}
						tag={"Anxiety"}
						readTime={"4 min"}
						source={"psychreg.org"}
					/>

					<WorkTemplate
						dark={matches ? true : false}
						URL="https://nosidebar.com/5-reasons-why-the-hustle-culture-is-toxic/"
						bgImage={
							"https://nosidebar.com/wp-content/uploads/marvin-meyer-SYTO3xs06fU-unsplash-1536x1024.jpg"
						}
						date={"Apr 27, 2023"}
						title={"5 Reasons Why the Hustle Culture Is Toxic"}
						tag={"Hustle"}
						readTime={"5 min"}
						source={"nosidebar.com"}
					/>

					<WorkTemplate
						dark={matches ? true : false}
						URL="https://introvertdear.com/news/10-secrets-of-an-introvert-living-in-an-extroverted-world/"
						bgImage={
							"https://introvertdear.com/wp-content/uploads/2022/01/10-secrets-of-an-introvert-living-in-an-extroverted-world.jpg"
						}
						date={"Jan 11, 2022"}
						title={
							"10 Secrets of an Introvert Living in an Extroverted World"
						}
						tag={"Introverts"}
						readTime={"5 min"}
						source={"introvertdear.com"}
					/>

					<WorkTemplate
						dark={matches ? false : true}
						URL="https://www.alustforlife.com/voice/personal-stories/how-writing-saved-my-life-a-journey"
						bgImage={
							"https://images.pexels.com/photos/261763/pexels-photo-261763.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
						}
						date={"Aug 17, 2022"}
						title={"How writing saved my life, a journey"}
						tag={"Life"}
						readTime={"4 min"}
						source={"alustforlife.com"}
					/>
					<WorkTemplate
						dark={matches ? false : true}
						URL="https://introvertdear.com/news/a-guide-for-parents-things-you-should-tell-your-introverted-child-more-often/"
						bgImage={
							"https://introvertdear.com/wp-content/uploads/2022/07/introvert-kid-needs-to-hear.jpg"
						}
						date={"Jul 20, 2022"}
						title={
							matches
								? "A Guide for Parents: Things You Should Tell Your Introverted Child More Often"
								: "A Guide for Parents: Things You Should Tell Your Introverted..."
							// if on mobile then dont show full title, code it
						}
						tag={"Introverts"}
						readTime={"5 min"}
						source={"introvertdear.com"}
						/* fontsize={64} */
					/>
					<WorkTemplate
						dark={matches ? true : false}
						URL="https://asmairfan2002.medium.com/effective-techniques-you-can-use-to-persuade-anyone-to-do-exactly-what-you-want-8d5c07539bfb"
						bgImage={
							"https://miro.medium.com/v2/resize:fit:720/format:webp/0*qBAuQn4mkv3WBnr4"
						}
						date={"Jul 31, 2021"}
						title={
							"Effective Techniques You Can Use To Persuade Anyone To Do Exactly What You Want"
						}
						tag={"Psychology"}
						readTime={"4 min"}
						source={"medium.com"}
					/>

					<WorkTemplate
						dark={matches ? true : false}
						URL="https://www.psychreg.org/supporting-loved-one-anxiety-how-effective-ally-caregiver/"
						bgImage={
							"https://static1.squarespace.com/static/656f4e4dababbd7c042c4946/657236350931ee4538eea52c/6572363e0931ee4538eeb4ee/1709765950362/help-a-friend-with-anxiety-1x1.jpg?format=1500w"
						}
						date={"Mar 06, 2023"}
						title={
							matches
								? "Supporting a Loved One with Anxiety: How to Be an Effective Ally and Caregiver"
								: "Supporting a Loved One with Anxiety: How to Be an Effective..."
							// if on mobile then dont show full title, code it
						}
						tag={"Anxiety"}
						readTime={"4 min"}
						source={"pychreg.org"}
					/>

					<WorkTemplate
						dark={matches ? false : true}
						URL="https://asmairfan2002.medium.com/ways-not-to-run-a-cryptocurrency-exchange-b2e9e19a0d37"
						bgImage={
							"https://miro.medium.com/v2/resize:fit:828/0*p-a6KLfcIMUjD_7e"
						}
						date={"Feb 06, 2022"}
						title={"6 Ways NOT To Run a Cryptocurrency Exchange"}
						tag={"Crypto"}
						readTime={"4 min"}
						source={"medium.com"}
					/>
					<WorkTemplate
						dark={matches ? false : true}
						URL="https://asmairfan2002.medium.com/7-relatable-things-for-a-highly-sensitive-person-living-in-a-mixed-race-brown-household-8c8f7d20faab"
						bgImage={
							"https://miro.medium.com/max/828/0*m1IlwoB5x0tsWmbB"
						}
						date={"Dec 3, 2022"}
						title={
							"7 Relatable Things For a Highly Sensitive Person Living In a Mixed..."
						}
						tag={"Sensitive"}
						readTime={"5 min"}
						source={"medium.com"}
					/>
					<WorkTemplate
						dark={matches ? true : false}
						URL="https://medium.com/@asmairfan2002/the-right-wakeboard-bindings-for-you-f06f0095c80b"
						bgImage={
							"https://miro.medium.com/max/828/0*71fbnwLCyBqskLmN"
						}
						date={"Nov 29, 2021"}
						title={"The Right Wakeboard Bindings For You!"}
						tag={"Product"}
						readTime={"5 min"}
						source={"medium.com"}
					/>
				</section>

				{/* <section id="clients">
                    <h2>A-Z of thoughtful clients</h2>
                    <p className="b20" style={{ marginTop: -40 }}>
                        Pellentesque imperdiet fringilla neque quis efficitur.
                        Etiam egestas iaculis nisi, sit amet pretium risus
                        imperdiet in.
                    </p>
                </section> */}

				<section id="about">
					{/* <h2 className="outlinedB">Me, My, Mine</h2> */}
					<div className="grid2">
						<div className="left">
							<h2 className="outlinedB">Me, My, Mine</h2>
							{/* <img src={ImageSrc} alt="" className="me" /> */}
							<div className="socials">
								<a
									href="https://asmairfan2002.medium.com/"
									target={"_blank"}
								>
									<img src={MediumIcon} alt="" />
								</a>
								<a
									href="https://www.instagram.com/asmairfan234/"
									target={"_blank"}
								>
									<img src={InstagramIcon} alt="" />
								</a>
								<a
									href="https://www.linkedin.com/in/asmairfan234"
									target={"_blank"}
								>
									<img src={LinkedInIcon} alt="" />
								</a>
								<a
									href="https://twitter.com/irfanasma234"
									target={"_blank"}
								>
									<img src={TwitterIcon} alt="" />
								</a>
							</div>
						</div>
						<p className="b28">
							After all is said and done, let me introduce myself
							for who I am and not what I do. When I was half an
							hour old, my parents named me Asma (not asthma, but
							yes I’m well aware of the humor you might get out of
							it). If I was to describe myself in a sentence this
							is what I would say; I am your typical protagonist
							in a realistic fiction book. I am a psychology major
							by day, I have a knack for binge-reading books from
							genres I’d never heard of before, caffeine is human
							fuel for me and most importantly, I live to write
							and write to live. I wouldn’t say that I am at the
							epitome of my journey as a writer but I have come
							been successful in crossing out a number of things
							off my checklist.
						</p>
					</div>
				</section>

				<section id="contact" className="grid2">
					<div className="heading">
						<h2>
							{matches ? (
								<Correction
									text="Go on,"
									correct="Hurry up!"
									color="white"
									style={{
										fontSize: 56,
										top: -56,
										right: 46,
									}}
								/>
							) : (
								" Go on, "
							)}
							leave a message!
						</h2>
						<p className="b20">
							I would love to connect with you for your projects
							or for constructive feedback on my work! So feel
							free to leave me a text, and I will respond as soon
							as possible.
						</p>
					</div>
					<form ref={form} onSubmit={sendEmail} className="contactMe">
						<div className="row" style={{ gap: 16 }}>
							<input
								type="text"
								className="b24"
								name="user_name"
								id="nameInput"
								placeholder="Name"
							/>
							<input
								type="email"
								className="b24"
								name="user_email"
								id="emailInput"
								placeholder="Email"
							/>
						</div>
						<input
							type="text"
							className="b24"
							name="subject"
							id="subjectInput"
							placeholder="Subject"
						/>
						<textarea
							name="message"
							className="b24"
							id="messageInput"
							rows="5"
							placeholder="Message"
						></textarea>
						<button type="submit" className="btnText24">
							Send Message
						</button>
					</form>
				</section>

				<footer className="grid2">
					<div className="heading">
						<h4>Asma Irfan</h4>
						<p className="b20">
							irfanasma234@gmail.com <br />
							Islamabad, Pakistan
						</p>
						<div className="socials">
							<a
								href="https://asmairfan2002.medium.com/"
								target={"_blank"}
							>
								<img src={MediumIcon} alt="" />
							</a>
							{/* <a
								href="https://www.instagram.com/asmairfan234/"
								target={"_blank"}
							>
								<img src={InstagramIcon} alt="" />
							</a> */}
							<a
								href="https://www.linkedin.com/in/asmairfan234"
								target={"_blank"}
							>
								<img src={LinkedInIcon} alt="" />
							</a>
							<a
								href="https://twitter.com/irfanasma234"
								target={"_blank"}
							>
								<img src={TwitterIcon} alt="" />
							</a>
							<a
								href="https://asmairfan.wixsite.com/writerssanctuary"
								target={"_blank"}
							>
								<img src={BlogIcon} alt="" />
							</a>
						</div>
					</div>
					<div className="subscribe">
						<h5>Join the list</h5>
						<p className="b20" /* style={{ marginTop: 32 }} */>
							Get exciting updates about the books I read every
							month!
						</p>
						<SubscriptionForm />
						{/* <form
							action=""
							className="subscribeFrom"
							style={{ marginTop: 32 }}
						>
							<input
								type="email"
								name="emailSubscribe"
								placeholder="Email"
								style={{
									marginBottom: 16,
									backgroundColor: "#fff",
								}}
							/>
							<button type="submit" className="btnText24">
								Subscribe
							</button>
						</form> */}
					</div>
				</footer>
				<div className="copyright">
					© Copyright Asma Irfan. All Rights Reserved.
				</div>
			</div>
		</div>
	);
}

export default App;
